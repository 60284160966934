




























































































































import { Component, Vue, Watch } from "vue-property-decorator";

export default class MedioAmbiente extends Vue {
  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
}
