var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row col-12 color_blanco justify-center q-pb-lg"},[_c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"\n        contenedor_title\n        col-7 col-sm-5\n        row\n        justify-center\n        color_principal\n      "},[_c('div',{class:'items-center row ' +
            (_vm.$q.screen.xs || _vm.$q.screen.sm ? 'col-12 q-ml-lg ' : 'col-7')},[_c('q-icon',{staticClass:"ambiente_icon",attrs:{"name":"park"}}),_vm._m(0)],1)]),_c('div',{staticClass:"col-5 col-sm-7 imagen_medioambiente"})]),_c('div',{staticClass:"row col-11 col-md-10"},[_c('div',{staticClass:"col-12 q-my-md q-pt-md"},[_c('div',[_c('q-breadcrumbs',{attrs:{"active-color":"primary"},scopedSlots:_vm._u([{key:"separator",fn:function(){return [_c('q-icon',{attrs:{"size":"1.5em","name":"chevron_right","color":"primary"}})]},proxy:true}])},[_c('q-breadcrumbs-el',{attrs:{"label":"Inicio","icon":"home","to":"/"}}),_c('q-breadcrumbs-el',{attrs:{"label":"Medio Ambiente","icon":"park"}})],1)],1)]),_c('div',{class:'row col-12 q-py-md ' +
          (_vm.$q.screen.xs || _vm.$q.screen.sm
            ? 'justify-center '
            : 'justify-between q-my-xl')},[_c('q-btn',{staticClass:"btn-1 col-10 col-md-3 q-ma-sm",attrs:{"unelevated":""}},[_c('div',{staticClass:"row items-center justify-start no-wrap"},[_c('q-icon',{attrs:{"left":"","name":"map","size":_vm.$q.screen.xs ? 'sm' : 'lg'}}),_c('p',{staticClass:"text-center text-bold"},[_vm._v("Denuncias")])],1)]),_c('q-btn',{staticClass:"btn-1 col-10 col-md-3 q-ma-sm",attrs:{"unelevated":""}},[_c('div',{staticClass:"row items-center no-wrap"},[_c('q-icon',{attrs:{"left":"","name":"map","size":_vm.$q.screen.xs ? 'sm' : 'lg'}}),_c('p',{staticClass:"text-center text-bold"},[_vm._v("Puntos verdes y reciclaje")])],1)]),_c('q-btn',{staticClass:"btn-1 col-10 col-md-3 q-ma-sm",attrs:{"unelevated":""},on:{"click":function($event){return _vm.toLink(
            'https://ilustre-municipalidad-de-Arica-Arica.hub.arcgis.com/',
            '_blank'
          )}}},[_c('div',{staticClass:"row items-center no-wrap"},[_c('q-icon',{attrs:{"left":"","name":"map","size":_vm.$q.screen.xs ? 'sm' : 'lg'}}),_c('p',{staticClass:"text-center text-bold"},[_vm._v("Acciones medioambientales")])],1)])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"q-pa-sm text-bold"},[_vm._v(" Medio "),_c('br'),_vm._v(" Ambiente ")])}]

export { render, staticRenderFns }