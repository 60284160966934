





































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

interface ArchivosType {
  fecha: string;
  titulo: string;
  url: string;
}
interface Archivos {
  periodo: string | null;
  actas_ordinarias: Array<ArchivosType>;
  actas_extraordinarias: Array<ArchivosType>;
  documentos: Array<ArchivosType>;
}

@Component({
  components: {}
})
export default class Concejo extends Vue {
  private win = window;
  private base = window.location;
  private menu = "";
  private menuLateral: any = [];
  private nombreWeb: any = "";
  private archivosCosoc: Array<Archivos> = [];

  private beforeMount() {}
  private mounted() {
    document.title =
      "Consejo Comunal de Organizaciones de la Sociedad Civil - Ilustre Municipalidad de Juan Fernández";
    this.setMenu();
    this.getArchivos();
  }

  private getArchivos() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("cosoc")
      .then(res => {
        let base = process.env.VUE_APP_BASE_URL;
        this.archivosCosoc = res.data;
        for (let i = 0; i < this.archivosCosoc.length; i++) {
          if (
            this.archivosCosoc[i].documentos &&
            this.archivosCosoc[i].documentos
          ) {
            for (let j = 0; j < this.archivosCosoc[i].documentos.length; j++) {
              this.archivosCosoc[i].documentos[j].url =
                base + this.archivosCosoc[i].documentos[j].url;
            }
          }
          if (
            this.archivosCosoc[i].actas_ordinarias &&
            this.archivosCosoc[i].actas_ordinarias
          ) {
            for (
              let j = 0;
              j < this.archivosCosoc[i].actas_ordinarias.length;
              j++
            ) {
              this.archivosCosoc[i].actas_ordinarias[j].url =
                base + this.archivosCosoc[i].actas_ordinarias[j].url;
            }
          }
          if (
            this.archivosCosoc[i].actas_extraordinarias &&
            this.archivosCosoc[i].actas_extraordinarias
          ) {
            for (
              let j = 0;
              j < this.archivosCosoc[i].actas_extraordinarias.length;
              j++
            ) {
              this.archivosCosoc[i].actas_extraordinarias[j].url =
                base + this.archivosCosoc[i].actas_extraordinarias[j].url;
            }
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.archivosCosoc = [];
      });
  }

  private descargarArchivo(url: string) {
    this.win.open(url, "_blank");
    return;
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private async setMenu() {
    const runtimeConfig1 = await fetch("/menu.json");
    this.menu = await runtimeConfig1.json();
    for (let i = 0; i < this.menu.length; i++) {
      this.getSubMenu(this.menu[i], "cosoc", 1);
    }
  }

  private getSubMenu(element: any, nombre_web: string, profundidad) {
    if (element.nombre_web == nombre_web) {
      return true;
    } else {
      if (element.sub_menu && element.sub_menu.length > 0) {
        for (let j = 0; j < element.sub_menu.length; j++) {
          if (
            this.getSubMenu(element.sub_menu[j], nombre_web, profundidad + 1)
          ) {
            if (profundidad == 2) {
              return true;
            } else {
              this.menuLateral = element.sub_menu;
              this.checkSubMenus();
              return false;
            }
          }
        }
      }
      return false;
    }
  }

  private selectedStyle(item: any) {
    if ("cosoc" == item.nombre_web) {
      item.expand = true;
      return " background-color: #4E7DBB !important; color: #f1f1f1 !important;font-family: 'lexendRegular' !important;";
    } else {
      let childSelected = false;
      if (item.sub_menu && item.sub_menu.length > 0) {
        for (let i = 0; i < item.sub_menu.length; i++) {
          if ("cosoc" == item.sub_menu[i].nombre_web) {
            childSelected = true;
          }
        }
        if (childSelected) {
          item.expand = true;
          return " background-color: #4E7DBB !important; color: #f1f1f1 !important;font-family: 'lexendRegular' !important;";
        }
      }
      return "";
    }
  }

  private checkSubMenus() {
    for (let i = 0; i < this.menuLateral.length; i++) {
      if (this.menuLateral[i].sub_menu.length > 0) {
        if (
          this.menuLateral[i].tipo == 2 &&
          "cosoc" == this.menuLateral[i].nombre_web
        ) {
          this.menuLateral[i].expand = true;
        }
      } else {
        this.menuLateral[i].expand = false;
      }
    }
  }

  private toLink(item: any, blank: any) {
    if (item && item != "") {
      if (blank == 1) {
        window.open(item, "_blank");
      } else {
        window.open(item, "_self");
      }
    } else {
      return;
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "contactos"
    ) {
      this.routerGo("Telefonos");
    } else if (
      nombreWeb == "organigrama-municipal" ||
      nombreWeb == "organigrama"
    ) {
      this.routerGo("Organigrama");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }

  private sendMail(correo: string) {
    window.open("mailto:" + correo);
  }
}
